import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "title": "InstarVision Surveillance Center",
  "path": "/Software/iOS/InstarVision/Wizard/iPhone/Add_Camera/New_P2P_Cam/",
  "dateChanged": "2017-12-12",
  "author": "Mike Polinowski",
  "excerpt": "Um mit unserer Android App InstarVision auf Ihre IP-Kamera zuzugreifen, empfehlen wir Ihnen, zuerst die neueste Version aus dem App Store herunterzuladen. Verwenden Sie das Suchwort Instar, um unsere App im Geschäft zu finden.",
  "image": "./P_SearchThumb_InstarVision_Android.png",
  "social": "/images/Search/P_SearchThumb_InstarVision_Android.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_P-Android-InstarVision_white.webp",
  "chapter": "Software"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2 {...{
      "id": "iphone-app-installationsassistent",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#iphone-app-installationsassistent",
        "aria-label": "iphone app installationsassistent permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`iPhone App Installationsassistent`}</h2>
    <h3 {...{
      "id": "hinzufügen-einer-neuen-p2p-kamera",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#hinzuf%C3%BCgen-einer-neuen-p2p-kamera",
        "aria-label": "hinzufügen einer neuen p2p kamera permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Hinzufügen einer neuen P2P-Kamera`}</h3>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/103945b1669f74e4bdaa3a1afe7dfee6/81315/iPhone_Wizard_New_P2P_Cam_d01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "108.26086956521739%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAWCAYAAADAQbwGAAAACXBIWXMAAAsTAAALEwEAmpwYAAACgUlEQVQ4y41VCW4bMQzU/1/XokCBomnT+Nx7dUurawrR3o2duE0MDCSTI4oUOTZTSmPoB+RUEEJGziCEmJFSQd+PEELD+wVt0yLGTKicUkCcCs4l+mECO/OIl2GBsgklFzRNR4ixIIYEZQJOc0QrEvbjgiVkeLdgtzvg+fmFAtWA1gXsxwDGhcMsPIR0UMphnjXmWdFeku3iqzwuPNmktJgmhWmS4NwQt6LyWHWuEMJeDzjar3jLEeLCkcrfcauf1ajiGuTeeWO7wXueveOx24OP8Brc3mX9L7BHJd6Xuwa7z/Dh5TXD18OPD6xB+axwPrUbDodmw/HY4bA/Yxw5WO2ONR5DP+F4rM4W+/2Z0LYTdU9Xjs+QHpC+QPmCnAtKuawpJRifYJcMJmxCncVORnRiQYyRCCnlDUssmJXFjz8NnnYtfu1adH2Ptm3R9z2cc/g5Ar8ngCnj0AwcI9foJgGlFHKVAUAZVIQE5BgQnEbwBoszMMZAaw3nPHFtKHChgGmfMZoM5RNQMmWXc6Kga8CYCpYE6AioAITLfduncraAXBqceo5uFOBcYJ45OOd0++V9MmXYTwJfvz/hadfgeXdC0zRUcnM+E/fLGfjWAKw+uNEOWrtNQivWThvtYY0Fnycs3mHxnkqtb1fXWk1tyKUp4nMDWzWtzULrW5Dmr/hQKR8N/j+V8rH0Pgd2K+5VZu9tt77/fSfpuYvsaiarBMWjEt/zHj0Fu81IbL95ZrvxteOf4zEpJZzRGEzBaIHFWRhjEUKAtZb+S7z3F2UoidkWdBqIoXZYkxDqHIYQicPSVRUpFxrsqs1h6CEEJzv5Utr2uRQIqTAQb8A8z5u/rn8BYdSf0rN0of4AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/103945b1669f74e4bdaa3a1afe7dfee6/e4706/iPhone_Wizard_New_P2P_Cam_d01.avif 230w", "/en/static/103945b1669f74e4bdaa3a1afe7dfee6/d1af7/iPhone_Wizard_New_P2P_Cam_d01.avif 460w", "/en/static/103945b1669f74e4bdaa3a1afe7dfee6/7f308/iPhone_Wizard_New_P2P_Cam_d01.avif 920w", "/en/static/103945b1669f74e4bdaa3a1afe7dfee6/e1c99/iPhone_Wizard_New_P2P_Cam_d01.avif 1380w", "/en/static/103945b1669f74e4bdaa3a1afe7dfee6/6e1e4/iPhone_Wizard_New_P2P_Cam_d01.avif 1656w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/103945b1669f74e4bdaa3a1afe7dfee6/a0b58/iPhone_Wizard_New_P2P_Cam_d01.webp 230w", "/en/static/103945b1669f74e4bdaa3a1afe7dfee6/bc10c/iPhone_Wizard_New_P2P_Cam_d01.webp 460w", "/en/static/103945b1669f74e4bdaa3a1afe7dfee6/966d8/iPhone_Wizard_New_P2P_Cam_d01.webp 920w", "/en/static/103945b1669f74e4bdaa3a1afe7dfee6/445df/iPhone_Wizard_New_P2P_Cam_d01.webp 1380w", "/en/static/103945b1669f74e4bdaa3a1afe7dfee6/87a8c/iPhone_Wizard_New_P2P_Cam_d01.webp 1656w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/103945b1669f74e4bdaa3a1afe7dfee6/81c8e/iPhone_Wizard_New_P2P_Cam_d01.png 230w", "/en/static/103945b1669f74e4bdaa3a1afe7dfee6/08a84/iPhone_Wizard_New_P2P_Cam_d01.png 460w", "/en/static/103945b1669f74e4bdaa3a1afe7dfee6/c0255/iPhone_Wizard_New_P2P_Cam_d01.png 920w", "/en/static/103945b1669f74e4bdaa3a1afe7dfee6/b1001/iPhone_Wizard_New_P2P_Cam_d01.png 1380w", "/en/static/103945b1669f74e4bdaa3a1afe7dfee6/81315/iPhone_Wizard_New_P2P_Cam_d01.png 1656w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/103945b1669f74e4bdaa3a1afe7dfee6/c0255/iPhone_Wizard_New_P2P_Cam_d01.png",
              "alt": "InstarVision iPhone Installation Wizard",
              "title": "InstarVision iPhone Installation Wizard",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <br />
    <ul>
      <li parentName="ul"><strong parentName="li">{`[01]`}</strong>{` Beginnen Sie mit dem Aufruf des Menüs `}<strong parentName="li">{`Kameras`}</strong>{` und wählen Sie die Option `}<strong parentName="li">{`Kamera hinzufügen`}</strong>{`.`}</li>
      <li parentName="ul"><strong parentName="li">{`[02]`}</strong>{` Sie können nun mit dem Hinzufügen des Fernzugriffs über den Dienst `}<a parentName="li" {...{
          "href": "/en/Software/iOS/InstarVision/iPhone/P2P/"
        }}>{`Point2Point (P2P)`}</a>{` oder `}<a parentName="li" {...{
          "href": "/en/Software/iOS/InstarVision/iPhone/DDNS/"
        }}>{`DDNS`}</a>{` fortfahren.`}</li>
    </ul>
    <br />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/34a81496aca9ce35020cc3351f6428ba/81315/iPhone_Wizard_New_P2P_Cam_d02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "108.26086956521739%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAWCAYAAADAQbwGAAAACXBIWXMAAAsTAAALEwEAmpwYAAAB10lEQVQ4y62USY/aUBCEn8HsiH0Ry4EjB/7/Pcdk4JADcHNAggicODCasQPYpqJq6Vkm0jBjEkult0j9ubrbbqWMNJSZh1LqTRWLRUynUxx+OfjtuTidTrhcLqLz+SxyHAf7/R5KmTkYpQaUkXoTWCgU8OnzV9ivwPZwxvH5Bd8sC5ZlYbvdCth1XZG658wwjMjhl6cpnl9cbL7vYNu2BNMp3YVhGOku8DblJxyPB+x3O/i+j/hzvV4jKe2k0Wig3W6j0+mg1WrJyrNOeT6fSzDTiwP+lgDL5bKITkqlUiR9l8/nsVwuBci03gUy+F66dLhYLAQYBMHHHKZSqagRcT0M1B39Lw4ZRIf1el3UbDZFfAnrlxhYq9XEZb/fR7fbFQ2HQ6ltpVJJDhyNRgLjOplMMB6Po7ter3fT5Q8BdZdzuZykzO+QkH+qYbValbQJorjnnT4nAuo68u/QZ+7ZGP2nJAbSTRxIGO8eAjKAKbK7g8FAxGYQyLomBmazWQmkWC+9J8g0zcdSvicCE3027w3Ym/Hl+/CDQMCci1wfGrCz2QzX0IfzGuCnB9m7nicv4fQm3PO8W2A6nUYmkxFxH09ZO/SDEPYPB6vVCuv1GpvNRoYu3dHtH2UYRiH6hqQPAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/34a81496aca9ce35020cc3351f6428ba/e4706/iPhone_Wizard_New_P2P_Cam_d02.avif 230w", "/en/static/34a81496aca9ce35020cc3351f6428ba/d1af7/iPhone_Wizard_New_P2P_Cam_d02.avif 460w", "/en/static/34a81496aca9ce35020cc3351f6428ba/7f308/iPhone_Wizard_New_P2P_Cam_d02.avif 920w", "/en/static/34a81496aca9ce35020cc3351f6428ba/e1c99/iPhone_Wizard_New_P2P_Cam_d02.avif 1380w", "/en/static/34a81496aca9ce35020cc3351f6428ba/6e1e4/iPhone_Wizard_New_P2P_Cam_d02.avif 1656w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/34a81496aca9ce35020cc3351f6428ba/a0b58/iPhone_Wizard_New_P2P_Cam_d02.webp 230w", "/en/static/34a81496aca9ce35020cc3351f6428ba/bc10c/iPhone_Wizard_New_P2P_Cam_d02.webp 460w", "/en/static/34a81496aca9ce35020cc3351f6428ba/966d8/iPhone_Wizard_New_P2P_Cam_d02.webp 920w", "/en/static/34a81496aca9ce35020cc3351f6428ba/445df/iPhone_Wizard_New_P2P_Cam_d02.webp 1380w", "/en/static/34a81496aca9ce35020cc3351f6428ba/87a8c/iPhone_Wizard_New_P2P_Cam_d02.webp 1656w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/34a81496aca9ce35020cc3351f6428ba/81c8e/iPhone_Wizard_New_P2P_Cam_d02.png 230w", "/en/static/34a81496aca9ce35020cc3351f6428ba/08a84/iPhone_Wizard_New_P2P_Cam_d02.png 460w", "/en/static/34a81496aca9ce35020cc3351f6428ba/c0255/iPhone_Wizard_New_P2P_Cam_d02.png 920w", "/en/static/34a81496aca9ce35020cc3351f6428ba/b1001/iPhone_Wizard_New_P2P_Cam_d02.png 1380w", "/en/static/34a81496aca9ce35020cc3351f6428ba/81315/iPhone_Wizard_New_P2P_Cam_d02.png 1656w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/34a81496aca9ce35020cc3351f6428ba/c0255/iPhone_Wizard_New_P2P_Cam_d02.png",
              "alt": "InstarVision iPhone Installation Wizard",
              "title": "InstarVision iPhone Installation Wizard",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <br />
    <ul>
      <li parentName="ul"><strong parentName="li">{`[03]`}</strong>{` Verwenden Sie die App, um den QR-Code von Ihrer Kamera oder Ihrem Kamerapaket zu scannen. Wenn Ihre Kamera keinen QR-Code hat, wählen Sie bitte im nächsten Schritt das Kameramodell manuell aus.`}</li>
      <li parentName="ul"><strong parentName="li">{`[04]`}</strong>{` Wählen Sie aus, ob es sich bei Ihrer Kamera um ein Schwenk-Neige-Modell oder eine Kamera mit festem Kamerakopf handelt.`}</li>
    </ul>
    <br />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/3799b1338591515418d69abf6b99d03f/81315/iPhone_Wizard_New_P2P_Cam_d03.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "108.26086956521739%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAWCAYAAADAQbwGAAAACXBIWXMAAAsTAAALEwEAmpwYAAAChklEQVQ4y61VTW/bMAz1f9pxh10K7A/usKEohmH7M8OGFdipLdA2wew4qeOvxPqwZPENpCLPLdrTSoCQTFGPjxJFZ13XYrct4UcLawzGcRQ1p/l2u0XTtlBqQFnksNbMa865eV7XNXa7HbK81lg/DPhTWxy0x6bIkRcFnPMYR4vuoPCnNihbg/vdgKMy8N5hv99jvV4LKBMZlMKq0simQAgABsVGjcPhIHo8HiV6IMI0BbgpwE8EFmutrPd9D601QNHup4CM0Tmdtm0kvaqqMAwDlFICSCFgmibZ4NwoawzUdd2s7MtBWLKy3OL6+hqr1Wp24DRYiAghBAwmAnrv0batpHt3dyf7mATbmLEApogpVf5mQFYGYHV+ktSappmZ0CnNpbAtK8sSNzc3wpA3MENOMbFj1Y5kjW+RA/HaUtk/7cmWZ8K0E8PEThg6L8z4THn+FJCDpnlWFAWurq5we3sroJz20oHnagwSjC9MbvWFlOUMl/mHEAGYFRcrjxE82uQ2nYcPJCUkYyA4HzA6J75ZijRag7apUe8rHPoWdfUAa/QcWY5h5AvhCqBnlQmdAAnKAb0FenMaLaClekiKm8f7hnB2QXj7CTi7AN5/Bt6dA28+AF8vTyknhsYTjpaBo/aWYBw9Oq/OAF9+Ec5/kIzfLgkXPwkfvxN+lzFoFjfgxTTS2adMoj4vgYRh2vC8/gsYxykAPsQxzVkDPbnl15LXB3xa9f+rr88wNQAuSm6k6aEvn9+yUYhfeOz36C1LZ9YDakVoLTA5C2Nt7DJaz/8YpTXUcECrCXtu0sGLjYWfJD9N9s9SFP4VlNudtDHWzWYT7QsmsUUBD1UtPvxPyfNcnmVa/wtK8LDpWlSmmwAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/3799b1338591515418d69abf6b99d03f/e4706/iPhone_Wizard_New_P2P_Cam_d03.avif 230w", "/en/static/3799b1338591515418d69abf6b99d03f/d1af7/iPhone_Wizard_New_P2P_Cam_d03.avif 460w", "/en/static/3799b1338591515418d69abf6b99d03f/7f308/iPhone_Wizard_New_P2P_Cam_d03.avif 920w", "/en/static/3799b1338591515418d69abf6b99d03f/e1c99/iPhone_Wizard_New_P2P_Cam_d03.avif 1380w", "/en/static/3799b1338591515418d69abf6b99d03f/6e1e4/iPhone_Wizard_New_P2P_Cam_d03.avif 1656w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/3799b1338591515418d69abf6b99d03f/a0b58/iPhone_Wizard_New_P2P_Cam_d03.webp 230w", "/en/static/3799b1338591515418d69abf6b99d03f/bc10c/iPhone_Wizard_New_P2P_Cam_d03.webp 460w", "/en/static/3799b1338591515418d69abf6b99d03f/966d8/iPhone_Wizard_New_P2P_Cam_d03.webp 920w", "/en/static/3799b1338591515418d69abf6b99d03f/445df/iPhone_Wizard_New_P2P_Cam_d03.webp 1380w", "/en/static/3799b1338591515418d69abf6b99d03f/87a8c/iPhone_Wizard_New_P2P_Cam_d03.webp 1656w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/3799b1338591515418d69abf6b99d03f/81c8e/iPhone_Wizard_New_P2P_Cam_d03.png 230w", "/en/static/3799b1338591515418d69abf6b99d03f/08a84/iPhone_Wizard_New_P2P_Cam_d03.png 460w", "/en/static/3799b1338591515418d69abf6b99d03f/c0255/iPhone_Wizard_New_P2P_Cam_d03.png 920w", "/en/static/3799b1338591515418d69abf6b99d03f/b1001/iPhone_Wizard_New_P2P_Cam_d03.png 1380w", "/en/static/3799b1338591515418d69abf6b99d03f/81315/iPhone_Wizard_New_P2P_Cam_d03.png 1656w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/3799b1338591515418d69abf6b99d03f/c0255/iPhone_Wizard_New_P2P_Cam_d03.png",
              "alt": "InstarVision iPhone Installation Wizard",
              "title": "InstarVision iPhone Installation Wizard",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <br />
    <ul>
      <li parentName="ul"><strong parentName="li">{`[05]`}</strong>{` Sobald Ihre Kamera angeschlossen ist, klicken Sie auf `}<strong parentName="li">{`Suchen`}</strong>{` und die App wird alle INSTAR-Kameras in Ihrem lokalen Netzwerk erkennen. Wenn Ihre Kamera nicht angezeigt wird, wählen Sie `}<a parentName="li" {...{
          "href": "/en/Software/iOS/InstarVision/iPad/P2P/"
        }}>{`manuell hinzufügen`}</a>{`.`}</li>
      <li parentName="ul"><strong parentName="li">{`[06]`}</strong>{` Sie werden nun aufgefordert, den Admin-Login Ihrer Kamera einzugeben. Wenn Sie es vorher nicht eingerichtet haben, wird dies der Standard-Userermane `}<strong parentName="li">{`admin`}</strong>{` und das Passwort `}<strong parentName="li">{`instar`}</strong>{` sein. Dies kann später innerhalb der App geändert werden.`}</li>
    </ul>
    <br />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "828px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/1052ba639d69db6125c44aa19a3f60a2/8efc2/iPhone_Wizard_New_P2P_Cam_d04.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "216.52173913043478%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAArCAYAAAB4pah1AAAACXBIWXMAAAsTAAALEwEAmpwYAAADMUlEQVRIx+1Xy24TMRT1Hv6KBRJUQuIfYMdjzwI+BAmqskbthgVqF+muUirSRE3TJKRtkqZ5TN6PSWbGc9C5jieTNJVKhVjF0pHta/v42PcmvqNmsxna7Q7SmQyq1Sp834fruphOpwK2J5OJIG63GAwGODs7w+XlpfSV780w7HdRKuTQbNzA87xooa3DMBRw89XNhsMhLi4uULuuwJtNoZq9KYpND9U+0BoGmMbISMAF2WxW0Ov1bpGy7wcayYqGM5hBOX0X+bqL85sJyo4rhPEj8kgkOzk5QbvdFgI7buZMMBpPkK646AymUAg1gAW01tER1xU7ZsH5hKwPNZSZBGhtCDqdDkqlElqtFq6urlCv18VZvKdyuYxmsyl9OsFxHFljRJgN1aoatulpTmJtFwRBILCqOLZ6CvaVXcB7KhaLsjOVWBQKBVFMhQTHWVcqFWnn83np00GRQhLSUKvVkE6nBYytVColdSaTQTKZFPvR0ZHYudHx8bGQ0lFUL4RxyTQyTAh6eDQaST0ej8XGPtvWzggg2dKRSWIXcBIDm+Fgd7xPiftB8ai8O3sX9CyPSRULD4ZrsS6MFL3FgKXCbreLfr8vNcPHevmuss7TopCq6BB6k96jd6mWV7FOpZ7X9mqMY8NF2NxVAh3K75S1IGDfgO1AmzlLR47vCqzi74sQBtoQJEohnn4J8Xw7xDPiq8HLbyFe7Mztc9vWdognn0Ps/DJr9UIh4M0j5MNPQL0C1HtAvQXUO0C9AR59BB5/mvffzsE5r4Gtbczv2fwnRH8OLMMZ8OMc2M0CezF8PzXYW7HvngI3AxuL0ZGXDQ8p8bVLClkbz90PvgZ0bG105H9ZNoQbwg3hhvA/Ed71iD8UajWViP9Zrks14ra1Ck32iehB13rxjLJ9e1xHZLcSAD3PYJl+MGM1W/toDgP8dvgUBkLNtKTRaEQkNvuPp4K0RQ89cxsusI99wQmRqlmVWlJfpsZWIcn4RWAVMrFiahclS/YTgqkcH/3eRKPvhvII+b4XfUJw3CokbOpsvwiY56jF5dp7MK/+daWMg4MDJBKJCIeHh9jf30cul1u603jC+QcMTAhjx7lL+QAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/1052ba639d69db6125c44aa19a3f60a2/e4706/iPhone_Wizard_New_P2P_Cam_d04.avif 230w", "/en/static/1052ba639d69db6125c44aa19a3f60a2/d1af7/iPhone_Wizard_New_P2P_Cam_d04.avif 460w", "/en/static/1052ba639d69db6125c44aa19a3f60a2/ec170/iPhone_Wizard_New_P2P_Cam_d04.avif 828w"],
              "sizes": "(max-width: 828px) 100vw, 828px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/1052ba639d69db6125c44aa19a3f60a2/a0b58/iPhone_Wizard_New_P2P_Cam_d04.webp 230w", "/en/static/1052ba639d69db6125c44aa19a3f60a2/bc10c/iPhone_Wizard_New_P2P_Cam_d04.webp 460w", "/en/static/1052ba639d69db6125c44aa19a3f60a2/712de/iPhone_Wizard_New_P2P_Cam_d04.webp 828w"],
              "sizes": "(max-width: 828px) 100vw, 828px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/1052ba639d69db6125c44aa19a3f60a2/81c8e/iPhone_Wizard_New_P2P_Cam_d04.png 230w", "/en/static/1052ba639d69db6125c44aa19a3f60a2/08a84/iPhone_Wizard_New_P2P_Cam_d04.png 460w", "/en/static/1052ba639d69db6125c44aa19a3f60a2/8efc2/iPhone_Wizard_New_P2P_Cam_d04.png 828w"],
              "sizes": "(max-width: 828px) 100vw, 828px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/1052ba639d69db6125c44aa19a3f60a2/8efc2/iPhone_Wizard_New_P2P_Cam_d04.png",
              "alt": "InstarVision iPhone Installation Wizard",
              "title": "InstarVision iPhone Installation Wizard",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <br />
    <ul>
      <li parentName="ul"><strong parentName="li">{`[07]`}</strong>{` Bitte wählen Sie einen Namen für Ihre Kamera.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      